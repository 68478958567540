import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Pagination from 'react-bootstrap/Pagination';
import ElementLink from '../ElementLink';
import styles from './ModulePagination.module.scss';

export const ModulePaginationPropTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func
};

export const PageButtonPropTypes = {
  pageNumber: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired
};

const PageButton = ({ pageNumber, active }) => (
  <Pagination.Item
    active={active}
    href={`/video-library/page/${pageNumber}`}
    className={active && styles.active}
    data-testid="Pagination-button">
    {pageNumber}
  </Pagination.Item>
);

const ModulePagination = ({ currentPage, totalPages, onPageChange }) => {
  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const pageButtons = [];

  React.useEffect(() => {
    const { slug } = router.query;
    setPage(slug?.length === 3 ? Number(slug[2]) : 1);
  }, [router.query]);

  for (let i = 1; i <= totalPages; i++) {
    pageButtons.push(<PageButton key={i} pageNumber={i} active={i === currentPage} />);
  }

  const maxButtons = 6;
  const numLow = Math.min(totalPages - maxButtons, Math.max(0, currentPage - 1));
  const numHigh = Math.min(currentPage + maxButtons - 1, totalPages);

  return totalPages > 1 && !!currentPage ? (
    <div
      data-testid="Pagination"
      className={styles.pagination}
      aria-live="polite"
      role="presentation"
      aria-label="pagination navigation">
      <Pagination>
        <Pagination.Prev disabled={currentPage === 1} href={`/video-library/page/${currentPage - 1}`} />
        {pageButtons.slice(numLow, numHigh)}
        <Pagination.Next disabled={currentPage === totalPages} href={`/video-library/page/${currentPage + 1}`} />
      </Pagination>

      {/* Mobile only */}
      <p className={styles.loadMore}>
        <ElementLink
          href="#"
          as="#"
          linkText="Read more"
          onClick={() => {
            if (currentPage < totalPages) {
              onPageChange(currentPage + 1);
            }
          }}>
          Read more
        </ElementLink>
      </p>
    </div>
  ) : null;
};

PageButton.propTypes = PageButtonPropTypes;

ModulePagination.propTypes = ModulePaginationPropTypes;
ModulePagination.defaultProps = {
  currentPage: null,
  onPageChange: null
};

export default ModulePagination;
